import React from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { Link } from "react-router-dom";

const Navbar = ({ title, path }) => {
  return (
    <div
      style={{
        zIndex: 9999,
        position: "relative",
      }}
    >
      <nav className="navbar">
        <p className="navbar__back">
          <MdKeyboardArrowLeft size={35} color="#7D32F9" />
        </p>
        <Link to={path}>{title}</Link>
      </nav>
    </div>
  );
};

export default Navbar;
