import React from "react";
import styles from "../styles/components/Loading.module.scss";

const Loading = () => {
  return (
    <span className={styles.loading}>
      Loading
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </span>
  );
};

export default Loading;
