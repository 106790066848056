// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HyperOcr_containerContent__Fo06G {
  height: 100vh;
  display: flex;
  padding-top: 3rem;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
}
.HyperOcr_containerContent__Fo06G .HyperOcr_uploadImage__B\\+XXO {
  background-color: rgba(125, 50, 249, 0.1411764706);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px dashed #7d32f9;
  padding: 1rem;
  border-radius: 5px;
  width: 100%;
}
.HyperOcr_containerContent__Fo06G .HyperOcr_uploadImage__B\\+XXO p {
  color: #fff;
}
.HyperOcr_containerContent__Fo06G .HyperOcr_uploadImage__B\\+XXO img {
  border-radius: 5px;
}
.HyperOcr_containerContent__Fo06G button {
  width: 100%;
  height: 61px !important;
  border-radius: 5px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 61px !important;
  background: #7d32f9;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.165px;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/pages/hyperOcr/HyperOcr.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,iBAAA;EACA,SAAA;EACA,sBAAA;EACA,mBAAA;AACF;AAAE;EACE,kDAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,0BAAA;EACA,aAAA;EACA,kBAAA;EACA,WAAA;AAEJ;AADI;EACE,WAAA;AAGN;AADI;EACE,kBAAA;AAGN;AAAE;EACE,WAAA;EACA,uBAAA;EACA,kBAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;EACA,wBAAA;EACA,YAAA;AAEJ","sourcesContent":[".containerContent {\n  height: 100vh;\n  display: flex;\n  padding-top: 3rem;\n  gap: 1rem;\n  flex-direction: column;\n  align-items: center;\n  .uploadImage {\n    background-color: #7d32f924;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    border: 2px dashed #7d32f9;\n    padding: 1rem;\n    border-radius: 5px;\n    width: 100%;\n    p {\n      color: #fff;\n    }\n    img {\n      border-radius: 5px;\n    }\n  }\n  button {\n    width: 100%;\n    height: 61px !important;\n    border-radius: 5px;\n    color: #fff;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 61px !important;\n    background: #7d32f9;\n    border: none;\n    color: #fff;\n    font-size: 16px;\n    font-weight: 700;\n    letter-spacing: -0.165px;\n    color: white;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerContent": `HyperOcr_containerContent__Fo06G`,
	"uploadImage": `HyperOcr_uploadImage__B+XXO`
};
export default ___CSS_LOADER_EXPORT___;
