// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes Loading_loading__0b8PH {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.Loading_loading__0b8PH {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: rgb(125, 50, 249);
}

.Loading_loading__0b8PH span {
  animation-name: Loading_loading__0b8PH;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
}

.Loading_loading__0b8PH span:nth-child(1) {
  animation-delay: 0.4s;
}

.Loading_loading__0b8PH span:nth-child(2) {
  animation-delay: 0.6s;
}

.Loading_loading__0b8PH span:nth-child(3) {
  animation-delay: 0.8s;
}`, "",{"version":3,"sources":["webpack://./src/styles/components/Loading.module.scss"],"names":[],"mappings":"AAAA;EACE;IAEE,UAAA;EAAF;EAEA;IACE,UAAA;EAAF;AACF;AAGA;EACE,WAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;EACA,iBAAA;EACA,wBAAA;AADF;;AAIA;EACE,sCAAA;EACA,wBAAA;EACA,mCAAA;AADF;;AAIA;EACE,qBAAA;AADF;;AAIA;EACE,qBAAA;AADF;;AAIA;EACE,qBAAA;AADF","sourcesContent":["@keyframes loading {\n  0%,\n  100% {\n    opacity: 0;\n  }\n  50% {\n    opacity: 1;\n  }\n}\n\n.loading {\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 1.5rem;\n  font-weight: bold;\n  color: rgb(125, 50, 249);\n}\n\n.loading span {\n  animation-name: loading;\n  animation-duration: 1.2s;\n  animation-iteration-count: infinite;\n}\n\n.loading span:nth-child(1) {\n  animation-delay: 0.4s;\n}\n\n.loading span:nth-child(2) {\n  animation-delay: 0.6s;\n}\n\n.loading span:nth-child(3) {\n  animation-delay: 0.8s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": `Loading_loading__0b8PH`
};
export default ___CSS_LOADER_EXPORT___;
