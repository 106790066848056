import { useLocation } from "react-router-dom";
import Navbar from "../../components/Navbar";

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

const Result = () => {
  const location = useLocation();
  const { data, from } = location.state || {};

  return (
    <div className="container">
      <Navbar title={"Result"} path={from || "/defaultPath"} />
      <div className="content">
        {from === "/hyperApps/HyperExtract" && (
          <div>
            {Array.isArray(data?.result) && data?.result.length > 0 ? (
              <p>
                {data?.result.map((item, index) => (
                  <span key={index}>
                    {item}
                    {/* Eğer mevcut item 5'in katıysa ve son item değilse <br/> eklenir */}
                    {index % 5 === 4 && index !== data?.result.length - 1 ? (
                      <br />
                    ) : null}
                    {/* Eğer item son item değilse ", " eklenir */}
                    {index !== data?.result.length - 1 ? ", " : null}
                  </span>
                ))}
              </p>
            ) : (
              <p>No Data Available or Not an Array</p>
            )}
          </div>
        )}
        {from === "/hyperApps/HyperOcr" && (
          <div>
            {Array.isArray(data?.ocr) && data?.ocr.length > 0 ? (
              <p>{data?.ocr[0].split("\n").join(", ")}</p>
            ) : (
              <p>No Data Available or Not an Array</p>
            )}
          </div>
        )}
        {(from === "/hyperApps/HyperContracts" ||
          from === "/hyperApps/HyperPosts") && (
          <div>
            <div className="row justify-content-center">
              <div className="col-md-8 mx-auto">
                <p className="chat-container">
                  <div
                    className={"bot-message"}
                    // avgTypingDelay={10}
                    // stdTypingDelay={1}
                  >
                    {
                      data.includes("```") ? (
                        data.split("```").map((part, index) =>
                          index % 2 === 1 ? (
                            <SyntaxHighlighter
                              language="javascript"
                              style={materialDark}
                              wrapLines={true}
                              customStyle={{
                                marginTop: "1rem",
                                padding: "1em",
                                paddingTop: "0em",
                                backgroundColor: "#1E1E1E",
                                borderRadius: "0.7rem",
                              }}
                            >
                              {part}
                            </SyntaxHighlighter>
                          ) : (
                            part
                          )
                        )
                      ) : (
                        // <Typist avgTypingDelay={10} stdTypingDelay={1}>
                        <ReactMarkdown>{data}</ReactMarkdown>
                      )
                      // </Typist>
                    }
                  </div>
                </p>
              </div>
            </div>
          </div>
        )}
        {(from === "/hyperApps/HyperArt" ||
          from === "/hyperApps/HyperImages") && (
          <div className="img-result">
            {data?.map((item, index) => (
              <img src={item} alt="HyperArt" />
            ))}
          </div>
        )}
        {from === "/hyperApps/HyperPortraits" && (
          <div className="img-result">
            <img src={data.image} alt="HyperArt" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Result;
