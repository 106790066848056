import React, { useEffect, useState } from "react";
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdCloudUpload,
} from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import axios from "axios";
import Loading from "../../components/Loading";
import styles from "./HyperExtract.module.scss";

const HyperExtract = ({ token, generalPath, accessToken }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ocrData, setOcrData] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      setUploadedFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target?.result);
      };
      reader.readAsDataURL(file);
    } else {
      setUploadedFile(null);
      setImagePreview(null);
    }
  };
  const CreateOcr = async () => {
    if (!uploadedFile) return;

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", uploadedFile);

      const response = await axios.post(
        `https://api.hypergpt.ai/ai/imagetotext?token=${accessToken}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setOcrData(response.data);
      navigate("/result", {
        state: { data: response.data, from: location.pathname },
      });
    } catch (error) {
      console.error("Error creating post:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <Navbar
        title={"HyperApps / HyperExtract"}
        path={`/hyperApps${generalPath}`}
      />
      <div className={styles.containerContent}>
        <div
          className={styles.uploadImage}
          onClick={() => document.querySelector(".input-field")?.click()}
        >
          <input
            name="file"
            type="file"
            onChange={handleFileChange}
            accept="image/*"
            className="input-field"
            hidden
          />
          {imagePreview ? (
            <img src={imagePreview} width={150} height={150} alt="Uploaded" />
          ) : (
            <>
              <MdCloudUpload color="#7d32f924" size={60} />
              <p>{uploadedFile ? "Added Images" : "Browse Files to upload"}</p>
            </>
          )}
        </div>
        <button
          type="button"
          className="btn btn-primary mt-5"
          onClick={CreateOcr}
          disabled={loading}
        >
          {loading ? "Processing..." : "Generate"}
        </button>
      </div>
    </div>
  );
};

export default HyperExtract;
