import React from "react";
import character from "../../assets/images/character.svg";
import logo from "../../assets/images/logo.svg";
import { Link } from "react-router-dom";
import meliuk from "../../assets/images/meliuk.svg";

const Home = () => {
  return (
    <div className={`home-hero-container`}>
      <div className="home-hero">
        <img className="home-hero-image" src={character} alt="" />
        <div className="home-hero-content">
          <img className={`home-hero-image-logo`} src={logo} alt="" />
          <span>
            Your <strong>one-stop</strong> <br /> <strong> destination </strong>
            for
            <br /> <strong> AI applications!</strong>
          </span>
          <img className={`home-hero-image-meliuk`} src={meliuk} alt="" />
        </div>
      </div>
      <Link
        className="
        home-hero-button
      "
        to="#"
      >
        <h4>What can I do with the HyperGPT bot?</h4>
        <span>
          This bot gives you the keys to the AI kingdom, ready to unlock all the
          cool AI apps and dive right in!
        </span>
      </Link>
      <Link className="home-hero-button" to={"/hypergpt"}>
        <h4>Discover AI Apps</h4>
      </Link>
    </div>
  );
};

export default Home;
