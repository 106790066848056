import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import HyperApps from "./pages/hyperApps/HyperApps";
import HyperStore from "./pages/hyperStore/HyperStore";
import MySpace from "./pages/MySpace/MySpace";
import Main from "./pages/main/Main";
import Home from "./pages/home/Home";
import HyperPosts from "./pages/hyperPosts/HyperPosts";
import HyperArt from "./pages/hyperArt/HyperArt";
import HyperOcr from "./pages/hyperOcr/HyperOcr";
import HyperExtract from "./pages/hyperExtract/HyperExtract";
import HyperChat from "./pages/hyperChat/HyperChat";
import HyperCodes from "./pages/hyperCodes/HyperCodes";
import HyperContracts from "./pages/hyperContracts/HyperContracts";
import Result from "./pages/result/Result";
import HyperImages from "./pages/hyperImages/HyperImages";
import Profile from "./pages/Profile/Profile";
import BuyCredit from "./pages/buyCredit/BuyCredit";
import HyperPortraits from "./pages/hyperPortraits/HyperPortraits";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import axios from "axios";

const App = () => {
  const exToken =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiZDU2MTAzOGMtNzk4My00MDEzLWE2ZTEtNTEzMjkwMmFjNmE1IiwiZXhwaXJlcyI6MTk1NTAzNTUzNi40MzU5NDUzfQ.UTEb3fy3uuqZawY6VgOwVgTHntBSR9XFHI7i_W5UD-g";

  const buttonClass = "mode-button";
  const inputClass = "mode-input";
  const textClass = "mode-text";

  const [exData, setExData] = useState("");
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    const webApp = window?.Telegram?.WebApp;
    const user = webApp?.initDataUnsafe?.user;
    setExData(user?.username);
  }, []);

  const [accessToken, setAccessToken] = useState("");

  const [profileData, setProfileData] = useState({
    userId: "",
    chatId: "",
    firstName: "",
    lastName: "",
    username: "",
    photo: "",
  });

  console.log("profileData", profileData);
  const viewData = window?.Telegram?.WebApp?.initDataUnsafe?.user;

  const generalPath = `?telegram_uid=${profileData?.userId}&telegram_chat_id=${profileData?.chatId}&telegram_first_name=${profileData?.firstName}&telegram_last_name=${profileData?.lastName}&telegram_username=${profileData?.username}&telegram_photo=${profileData?.photo}&access_token=${profileData?.access_token}`;

  const viewPath = `?telegram_uid=${viewData?.id}&telegram_chat_id=${profileData?.chatId}&telegram_first_name=${viewData?.first_name}&telegram_last_name=${viewData?.last_name}&telegram_username=${viewData?.username}&telegram_photo=${profileData?.photo}&access_token=${profileData?.access_token}`;

  const getBalance = async () => {
    try {
      if (!accessToken) return;
      const response = await axios.get(
        `https://api.hypergpt.ai/user/getBalance?token=${accessToken}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log("ahahahah response", response.data);
      setBalance(response.data);
    } catch (error) {
      console.error("API'den veri çekilirken bir hata oluştu", error);
    } finally {
    }
  };
  useEffect(() => {
    getBalance();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <TonConnectUIProvider
      // manifestUrl="http://localhost:3000/tonconnect-manifest.json"
      manifestUrl="https://telegram-app-virid.vercel.app/tonconnect-manifest.json"
      actionsConfiguration={{
        twaReturnUrl: "https://t.me/HyperGPTai_bot",
      }}
    >
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <Main
                setAccessToken={setAccessToken}
                accessToken={accessToken}
                generalPath={profileData?.userId ? generalPath : viewPath}
                setProfileData={setProfileData}
                profileData={profileData}
                token={exToken}
                buttonClass={buttonClass}
                inputClass={inputClass}
                textClass={textClass}
              />
            }
          />
          <Route
            path="/HyperApps"
            element={
              <HyperApps
                accessToken={accessToken}
                generalPath={profileData?.userId ? generalPath : viewPath}
                profileData={profileData}
                token={exToken}
                buttonClass={buttonClass}
                inputClass={inputClass}
                textClass={textClass}
              />
            }
          />
          <Route
            path="/MySpace"
            element={
              <MySpace
                accessToken={accessToken}
                generalPath={profileData?.userId ? generalPath : viewPath}
                profileData={profileData}
                token={exToken}
                buttonClass={buttonClass}
                inputClass={inputClass}
                textClass={textClass}
              />
            }
          />
          <Route
            path="/Profile"
            element={
              <Profile
                accessToken={accessToken}
                generalPath={profileData?.userId ? generalPath : viewPath}
                profileData={profileData}
                balance={balance}
                getBalance={getBalance}
              />
            }
          />
          <Route
            path="/buy-credit"
            element={
              <BuyCredit
                accessToken={accessToken}
                generalPath={profileData?.userId ? generalPath : viewPath}
                profileData={profileData}
                setBalance={setBalance}
                getBalance={getBalance}
              />
            }
          />
          <Route
            path="/HyperApps/HyperPosts"
            element={
              <HyperPosts
                accessToken={accessToken}
                generalPath={profileData?.userId ? generalPath : viewPath}
                profileData={profileData}
                token={exToken}
                buttonClass={buttonClass}
                inputClass={inputClass}
                textClass={textClass}
              />
            }
          />
          <Route
            path="/HyperApps/HyperArt"
            element={
              <HyperArt
                accessToken={accessToken}
                generalPath={profileData?.userId ? generalPath : viewPath}
                profileData={profileData}
                token={exToken}
                buttonClass={buttonClass}
                inputClass={inputClass}
                textClass={textClass}
              />
            }
          />
          <Route
            path="/HyperApps/HyperImages"
            element={
              <HyperImages
                accessToken={accessToken}
                generalPath={profileData?.userId ? generalPath : viewPath}
                profileData={profileData}
                token={exToken}
                buttonClass={buttonClass}
                inputClass={inputClass}
                textClass={textClass}
              />
            }
          />
          <Route
            path="/HyperApps/HyperPortraits"
            element={
              <HyperPortraits
                accessToken={accessToken}
                generalPath={profileData?.userId ? generalPath : viewPath}
                profileData={profileData}
                token={exToken}
                buttonClass={buttonClass}
                inputClass={inputClass}
                textClass={textClass}
              />
            }
          />
          <Route
            path="/HyperApps/HyperOcr"
            element={
              <HyperOcr
                accessToken={accessToken}
                generalPath={profileData?.userId ? generalPath : viewPath}
                profileData={profileData}
                token={exToken}
                buttonClass={buttonClass}
                inputClass={inputClass}
                textClass={textClass}
              />
            }
          />
          <Route
            path="/HyperApps/HyperExtract"
            element={
              <HyperExtract
                accessToken={accessToken}
                generalPath={profileData?.userId ? generalPath : viewPath}
                profileData={profileData}
                token={exToken}
                buttonClass={buttonClass}
                inputClass={inputClass}
                textClass={textClass}
              />
            }
          />
          <Route
            path="/HyperApps/HyperChat"
            element={
              <HyperChat
                accessToken={accessToken}
                generalPath={profileData?.userId ? generalPath : viewPath}
                profileData={profileData}
                token={exToken}
                buttonClass={buttonClass}
                inputClass={inputClass}
                textClass={textClass}
              />
            }
          />
          <Route
            path="/HyperApps/HyperCodes"
            element={
              <HyperCodes
                accessToken={accessToken}
                generalPath={profileData?.userId ? generalPath : viewPath}
                token={exToken}
                buttonClass={buttonClass}
                inputClass={inputClass}
                textClass={textClass}
              />
            }
          />
          <Route
            path="/HyperApps/HyperCodes"
            element={
              <HyperCodes
                accessToken={accessToken}
                generalPath={profileData?.userId ? generalPath : viewPath}
                profileData={profileData}
                token={exToken}
                buttonClass={buttonClass}
                inputClass={inputClass}
                textClass={textClass}
              />
            }
          />
          <Route
            path="/HyperApps/HyperContracts"
            element={
              <HyperContracts
                accessToken={accessToken}
                generalPath={profileData?.userId ? generalPath : viewPath}
                profileData={profileData}
                token={exToken}
                buttonClass={buttonClass}
                inputClass={inputClass}
                textClass={textClass}
              />
            }
          />
          <Route
            path="Result"
            element={
              <Result
                accessToken={accessToken}
                generalPath={profileData?.userId ? generalPath : viewPath}
                profileData={profileData}
                token={exToken}
                buttonClass={buttonClass}
                inputClass={inputClass}
                textClass={textClass}
              />
            }
          />
          <Route
            path="/HyperStore"
            element={
              <HyperStore
                accessToken={accessToken}
                generalPath={profileData?.userId ? generalPath : viewPath}
                profileData={profileData}
                token={exToken}
                buttonClass={buttonClass}
                inputClass={inputClass}
                textClass={textClass}
              />
            }
          />
        </Routes>
      </Router>
    </TonConnectUIProvider>
  );
};

export default App;
