import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import styles from "./BuyCredit.module.scss";
import axios from "axios";
import { TonConnectUI, useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";

import "buffer";
import { beginCell, Address, TonClient, JettonMaster } from "@ton/ton";
import TonWeb from "tonweb";
import { toast, ToastContainer } from "react-toastify";
import Select, { components } from "react-select";
import "react-toastify/dist/ReactToastify.css";
import { BN } from "bn.js";
import {
  getJettonData,
  fetchJettonPrice,
  checkBalance,
  sendTx,
  addOrdHistory,
  addOrdHistoryLog,
  hgptjettonTransfer,
  tonTransfer,
} from "../../utils/buyCredit";

const TelegramProfile = ({
  profileData,
  generalPath,
  accessToken,
  getBalance,
  setBalance,
}) => {
  const [packages, setPackages] = useState([]);
  const [selectedCrypto, setSelectedCrypto] = useState({});

  const jettonAddress = "EQDEPcKFlXaKddKJVUvq2Rfn2QgH7_MyFHxZek99FdMyq8XD";
  const jettonAddress2 = "EQAWAYH5M3nvaUmmlcfk4onD0PiHOGJdUsFDQyg846zn8ytF";
  // EQDnFXwHX6NpU8NR0lZIKaLaaBC6r-cqUVmSrEoILRFEN3gw
  const receiver_address = "UQAuz15H1ZHrZ_psVrAra7HealMIVeFq0wguqlmFno1f3B-m";
  const userFriendlyAddress = useTonAddress();

  const [tonConnectUI, setOptions] = useTonConnectUI();

  const client = new TonClient({
    endpoint: "https://toncenter.com/api/v2/jsonRPC",
    apiKey: "d9f1c7b9b016b623c12bafd08907166bd9f2882a742a5f75ff6f9ab1e76dec20",
  });
  const tonweb = new TonWeb();

  // const [tonConnectUI] = useTonConnectUI();

  const networkOptions = [
    {
      value: "HyperGPT",
      label: "HGPT",
      icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/25419.png",
      ticker: "HGPT",
    },
    {
      value: "Toncoin",
      label: "TON",
      icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/11419.png",
      ticker: "TON",
    },
  ];

  const { Option } = components;

  const IconOption = (props) => (
    <div {...props.innerProps} className={styles.option}>
      <img
        src={props.data.icon}
        style={{ width: "35px", height: "35px", marginRight: "10px" }}
        alt={props.data.label}
      />
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>{props.data.label}</span>
        <small style={{ color: "gray" }}>{props.data.value}</small>
      </div>
    </div>
  );

  useEffect(() => {
    const getPackages = async () => {
      try {
        const response = await axios.get(`https://api.hypergpt.ai/app/api_packages`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const sortedPackages = response.data.sort((a, b) => a.order - b.order);
        setPackages(sortedPackages);
      } catch (error) {
        console.error("API'den veri çekilirken bir hata oluştu", error);
      }
    };

    getPackages();
  }, [accessToken]);

  const handleBuyCredit = async (item) => {
    console.log("selectedCrypto", selectedCrypto);
    console.log("item", item);
    if (userFriendlyAddress === "") {
      return toast.warning("Please Connect Wallet!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }

    console.log("item.id", item.id);
    if (selectedCrypto[item?.id] === undefined) {
      return toast.warning("Please Select Token!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
    if (selectedCrypto[item?.id] === "HyperGPT") {
      await hgptjettonTransfer(
        item.id,
        item.name,
        item.discount_price ? item.discount_price : item.price,
        "TonWeb",
        userFriendlyAddress,
        item.credit,
        accessToken,
        getBalance,
        userFriendlyAddress,
        tonConnectUI
      );
    } else {
      await tonTransfer(
        item.id,
        item.name,
        item.discount_price ? item.discount_price : item.price,
        "TonWeb",
        userFriendlyAddress,
        item.credit,
        accessToken,
        getBalance,
        userFriendlyAddress,
        tonConnectUI
      );
    }
  };

  return (
    <div className={`container ${styles.credit}`}>
      {<Navbar title={"Buy Credit"} path={`/${generalPath}`} />}
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className={styles.creditContainer}>
        {packages.map((item, index) => {
          return (
            <div key={item?.id} className={styles.card}>
              {item.discount_description && (
                <div className={styles.discountDesc}>{item.discount_description}</div>
              )}
              <div
                style={{
                  marginTop: item.discount_description ? "4.5rem" : "0",
                }}
                className={styles.cardContent}
              >
                <h2>{item?.name}</h2>
                <span
                  style={{
                    margin: "2rem 0rem",
                  }}
                >
                  {item.discount_price && (
                    <strong className={styles.discountPrice}>
                      ${item.discount_price ?? 0}
                    </strong>
                  )}
                  <strong
                    style={{
                      textDecoration: item.discount_price ? "line-through" : "none",
                    }}
                  >
                    ${item.price}
                  </strong>{" "}
                  <p className={styles.credit}>/</p>{" "}
                  <p className={styles.credit}>{item.credit}</p>
                </span>
                <div className={styles.cardLines}>
                  <p>{item?.line1}</p>
                  <p>{item?.line2}</p>
                  <p>{item?.line3}</p>
                </div>
              </div>
              <div className={styles.cardBtnFooter}>
                <Select
                  className={styles.select}
                  placeholder="Choose Token"
                  options={networkOptions}
                  onChange={(e) => {
                    // setSelectedNetwork(e.value)
                    setSelectedCrypto({
                      ...selectedCrypto,
                      [item?.id]: e.value, // Her paketin ID'sine göre seçili ağın güncellenmesi
                    });
                  }}
                  components={{ Option: IconOption }}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      background: "#0E2929",
                      border: "2px solid #CD5F96",
                      color: "#CD5F96",
                      borderRadius: "5px",
                      fontSize: "1.2rem",
                      height: "50px",
                      flexWrap: "nowrap",
                      whiteSpace: "nowrap",
                      width: "100%",
                      padding: "0 0.5rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }),
                    singleValue: (provided, state) => ({
                      ...provided,
                      color: "#CD5F96",
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                      whiteSpace: "nowrap",
                      flexWrap: "nowrap",
                      overflow: "visible",
                    }),
                    indicatorSeparator: (provided, state) => ({
                      ...provided,
                      display: "none",
                    }),
                    dropdownIndicator: (provided, state) => ({
                      ...provided,
                      color: "#CD5F96",
                      "&:hover": {
                        color: "#CD5F96",
                      },
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      background: "#0E2929",
                      border: "2px solid #CD5F96",
                      color: "#fff",
                      borderRadius: "5px",
                      fontSize: "1.2rem",
                      width: "100%",
                      minWidth: "200px",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      background: "#0E2929",
                      color: "#fff",
                      borderRadius: "5px",
                      fontSize: "1.2rem",
                      cursor: "pointer",
                      minWidth: "200px",

                      "&:hover": {
                        background: "#CD5F96",
                        color: "#fff",
                      },
                    }),
                    placeholder: (provided, state) => ({
                      ...provided,
                      color: "#CD5F96",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }),
                  }}
                  isSearchable={false}
                />
                <button
                  onClick={() => {
                    handleBuyCredit(item);
                  }}
                  style={{ whiteSpace: "nowrap" }}
                >
                  BUY NOW
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TelegramProfile;
