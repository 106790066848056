// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Profile_profile__AE9lk .Profile_profileContainer__xrX4O {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.Profile_profile__AE9lk .Profile_profileContainer__xrX4O strong {
  color: #7d32f9 !important;
  width: 40%;
}
.Profile_profile__AE9lk .Profile_profileContainer__xrX4O p {
  border-bottom: 1px solid rgba(128, 128, 128, 0.452);
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #fff !important;
  font-weight: 700 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Profile/Profile.module.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AAAJ;AACI;EACE,yBAAA;EACA,UAAA;AACN;AAEI;EACE,mDAAA;EACA,oBAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;EACA,sBAAA;EACA,2BAAA;AAAN","sourcesContent":[".profile {\n  .profileContainer {\n    margin-top: 1.5rem;\n    display: flex;\n    flex-direction: column;\n    gap: 2rem;\n    strong {\n      color: #7d32f9 !important;\n      width: 40%;\n    }\n\n    p {\n      border-bottom: 1px solid rgba(128, 128, 128, 0.452);\n      padding-bottom: 1rem;\n      display: flex;\n      align-items: center;\n      gap: 1rem;\n      color: #fff !important;\n      font-weight: 700 !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profile": `Profile_profile__AE9lk`,
	"profileContainer": `Profile_profileContainer__xrX4O`
};
export default ___CSS_LOADER_EXPORT___;
