import React, { useEffect, useState } from "react";
import hyperLogo from "../../assets/icon/hyper.svg";
import hyperappsLogo from "../../assets/icon/hyperapps.png";
import ProfileIcon from "../../assets/icon/ProfileIcon.png";
import BuyIcon from "../../assets/icon/BuyIcon.png";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import TelegramLoginButton, { TelegramUser } from "telegram-login-button";
import { TonConnectButton } from "@tonconnect/ui-react";
import {
  useTonAddress,
  useTonWallet,
  useTonConnectUI,
  useTonConnectModal,
  useIsConnectionRestored,
} from "@tonconnect/ui-react";
import { useLocation } from "react-router-dom";

import "buffer";
import { beginCell, Address, TonClient, JettonMaster } from "@ton/ton";
import TonWeb from "tonweb";
import axios from "axios";

const Main = ({
  token,
  buttonClass,
  inputClass,
  textClass,
  setProfileData,
  profileData,
  setAccessToken,
}) => {
  const [user, setUser] = useState(null);
  const userFriendlyAddress = useTonAddress();
  const rawAddress = useTonAddress(false);
  const wallet = useTonWallet();
  const [tonConnectUI, setOptions] = useTonConnectUI();
  const { state, open, close } = useTonConnectModal();
  const connectionRestored = useIsConnectionRestored();
  const currentIsConnectedStatus = tonConnectUI.connected;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const userId = params.get("telegram_uid") || "";
  const chatId = params.get("telegram_chat_id") || "";
  const firstName = params.get("telegram_first_name") || "";
  const lastName = params.get("telegram_last_name") || "";
  const username = params.get("telegram_username") || "";
  const photo = params.get("telegram_photo") || "";
  const access_token = params.get("access_token") || "";

  const client = new TonClient({
    endpoint: "https://toncenter.com/api/v2/jsonRPC",
    apiKey: "d9f1c7b9b016b623c12bafd08907166bd9f2882a742a5f75ff6f9ab1e76dec20",
  });
  const tonweb = new TonWeb();

  const handleConnect = async () => {
    if (currentIsConnectedStatus) {
      await tonConnectUI.disconnect();
      return;
    }
    await tonConnectUI.openModal();
  };

  useEffect(() => {
    const fetchApps = async () => {
      try {
        // setIsLoading(true);
        if (!profileData.userId) return;
        const response = await axios.post(
          `https://api.hypergpt.ai/user/telegram_login?telegram_uid=${profileData.userId}`
        );
        const accessToken = response.data.access_token;

        // Mevcut sayfanın URL'sini alın
        const currentUrl = window.location.href;

        // URL'ye access_token parametresini ekleyin
        const newUrl = `${currentUrl}&access_token=${accessToken}`;

        sessionStorage.setItem("access_token", accessToken);
        setAccessToken(accessToken);
        console.log("response:", accessToken);

        // newUrl'i kullanabilirsiniz
        console.log("Yeni URL:", newUrl);
      } catch (error) {
        console.error("API'den veri çekilirken bir hata oluştu", error);
      } finally {
        // setIsLoading(false);
      }
    };

    fetchApps();
  }, [
    profileData,
    userId,
    chatId,
    firstName,
    lastName,
    username,
    photo,
    access_token,
  ]);

  useEffect(() => {
    // URL'den parametreleri çekmek için kullanacağımız işlevi tanımlayın.
    const getURLParameters = () => {
      return {
        userId,
        chatId,
        firstName,
        lastName,
        username,
        photo,
        access_token,
      };
    };

    const urlParams = getURLParameters();
    sessionStorage.setItem("params", JSON.stringify(urlParams));
    setProfileData(urlParams);
  }, [location]);

  const [apps, setApps] = useState([
    {
      appid: 0,
      name: "HyperStore",
      description: "Discover all AI applications and start using them!",
      icon: hyperLogo,
      link: `/hyperStore`,
    },
    {
      appid: 1,
      name: "HyperApps",
      description: "Start using HyperGPT's unique AI applications now!",
      icon: hyperappsLogo,
      link: `/hyperApps`,
    },
    // {
    //   appid: 2,
    //   name: "My Space",
    //   description: "View your app list",
    //   icon: hyperappsLogo,
    //   link: `/MySpace`,
    // },
    {
      appid: 4,
      name: "Buy Credit",
      description: "Buy Credit for HyperGPT's unique AI applications now!",
      icon: BuyIcon,
      link: `/buy-credit`,
    },
    {
      appid: 3,
      name: "Profile",
      description: "View your profile",
      icon: ProfileIcon,
      link: `/profile`,
    },
  ]);

  const ListCard = (app) => (
    <Link to={`${app.link}`} className="listCard" key={app.appid}>
      <div className="card-image">
        <img
          className="card-icon"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = hyperLogo;
          }}
          src={app.icon}
          alt={app.name}
        />
        <div className="card-content">
          <h4 className={`card-title `}>{app.name}</h4>
          <span title={app.description} className="card-description">
            {app.description && app.description.length > 50
              ? app.description.substring(0, 70) + "..."
              : app.description}
          </span>
        </div>
      </div>
      <MdKeyboardArrowRight size={40} color="#7D32F9" />
    </Link>
  );

  return (
    <div className="container">
      {/* {<Navbar title={"HyperGPT"} path={"/"} />} */}
      {apps.map((app) => ListCard(app))}
      {/* <TonConnectButton
        style={{
          float: "right",
          marginTop: "2rem",
          width: "100%",
        }}
      /> */}
      <button
        style={{
          width: "100%",
          marginTop: "2rem",
          padding: "0.45rem",
          borderRadius: "10px",
          color: "white",
          backgroundColor: "#7D32F9",
          fontSize: "1rem",
          fontWeight: "bold",
          cursor: "pointer",
        }}
        onClick={() => handleConnect()}
      >
        {currentIsConnectedStatus
          ? "Disconnect " +
            userFriendlyAddress.slice(0, 4) +
            "......" +
            userFriendlyAddress.slice(
              userFriendlyAddress.length - 6,
              userFriendlyAddress.length
            )
          : "Connect Wallet"}
      </button>
    </div>
  );
};

export default Main;
