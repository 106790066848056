import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import styles from "./Profile.module.scss";

const TelegramProfile = ({
  profileData,
  generalPath,
  setAccessToken,
  accessToken,
  balance,
  getBalance,
}) => {
  console.log("profileData:", profileData);
  console.log("balance:", balance);
  // const [balance, setBalance] = useState(0);

  // useEffect(() => {
  //   const getBalance = async () => {
  //     try {
  //       if (!accessToken) return;
  //       const response = await axios.get(
  //         `https://api.hypergpt.ai/user/getBalance?token=${accessToken}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${accessToken}`,
  //           },
  //         }
  //       );
  //       setBalance(response.data);
  //     } catch (error) {
  //       console.error("API'den veri çekilirken bir hata oluştu", error);
  //     } finally {
  //     }
  //   };

  //   getBalance();
  // }, []);

  useEffect(() => {
    getBalance();
  }, []);

  return (
    <div className={`container ${styles.profile}`}>
      {<Navbar title={"Profile"} path={`/${generalPath}`} />}
      <div className={styles.profileContainer}>
        <p>
          <strong>Telegram ID</strong>
          {profileData.userId}
        </p>

        <p>
          <strong>Name</strong> {profileData.firstName}
        </p>

        <p>
          <strong>Username</strong> {profileData.username}
        </p>
        <p>
          <strong>Balance</strong> {balance}
        </p>
      </div>
    </div>
  );
};

export default TelegramProfile;
