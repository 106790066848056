import "buffer";
import TonWeb from "tonweb";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BN } from "bn.js";
import axios from "axios";
import { TonConnectUI, useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { beginCell, Address, TonClient, JettonMaster, toNano } from "@ton/ton";

const jettonAddress = "EQDEPcKFlXaKddKJVUvq2Rfn2QgH7_MyFHxZek99FdMyq8XD";
const jettonAddress2 = "EQAWAYH5M3nvaUmmlcfk4onD0PiHOGJdUsFDQyg846zn8ytF";
// EQDnFXwHX6NpU8NR0lZIKaLaaBC6r-cqUVmSrEoILRFEN3gw
const receiver_address = "UQAuz15H1ZHrZ_psVrAra7HealMIVeFq0wguqlmFno1f3B-m";
const client = new TonClient({
  endpoint: "https://toncenter.com/api/v2/jsonRPC",
  apiKey: "d9f1c7b9b016b623c12bafd08907166bd9f2882a742a5f75ff6f9ab1e76dec20",
});
const tonweb = new TonWeb();

export const getJettonData = async (jettonWallet) => {
  try {
    return await jettonWallet.getData();
  } catch {
    return toast.warning("You do not have this token!", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  }
};

export const fetchJettonPrice = async (jettonName) => {
  try {
    const jettonFetch = await axios.get("https://api.ston.fi/v1/assets");

    return jettonFetch.data.asset_list.find((item) => item.symbol === jettonName)
      .dex_price_usd;
  } catch (error) {
    console.log(error);
  }
};

export const checkBalance = async (
  jettonData,
  package_buying_price,
  rastgeleJettonPrice
) => {
  console.log("ortaya çıkan miktar", package_buying_price / rastgeleJettonPrice);
  const jettonAmount = (package_buying_price / rastgeleJettonPrice)
    .toString()
    .slice(0, 9);

  console.log("jettonAmount:", jettonAmount);
  const userJettonBalance = BN(jettonData.balance, "hex").toString();
  console.log("userJettonBalance:", tonweb.utils.fromNano(userJettonBalance));

  if (Number(tonweb.utils.fromNano(userJettonBalance)) < Number(jettonAmount)) {
    return toast.warning("You don't have enough balance!", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  }
  return jettonAmount;
};

export const sendTx = async (tonConnectUI, myTransaction) => {
  const tx = await tonConnectUI.sendTransaction(myTransaction, {
    twaReturnUrl: "https://t.me/HyperGPTai_bot",
    returnStrategy: "redirect", // "wait" | "redirect" | "none"
  });
  console.log("tx:", tx);

  const bocCell = tonweb.boc.Cell.oneFromBoc(tonweb.utils.base64ToBytes(tx.boc));

  const hash = tonweb.utils.bytesToBase64(await bocCell.hash());
  console.log("Transaction Hash:", hash);
  return `https://tonscan.org/tx/by-msg-hash/` + hash;
};

export const addOrdHistory = async (
  accessToken,
  package_id,
  package_name,
  package_price,
  payment_method,
  payment_detail,
  package_credit
) => {
  try {
    // `https://api.hypergpt.ai/user/add_user_order_history?token=${accessToken}&package_id=${package_id}&package_name=${package_name}&package_price=${package_price}&payment_method=${payment_method}&payment_detail=${payment_detail}&package_credit=${package_credit}`,
    // `https://server.hypergpt.ai/user/add_user_order_history?token=${accessToken}&package_id=${package_id}&package_name=${package_name}&package_price=${package_price}&payment_method=${payment_method}&payment_detail=${payment_detail}&package_credit=${package_credit}`
    if (!accessToken) return;
    const response = await axios.post(
      `https://api.hypergpt.ai/user/add_user_order_history?token=${accessToken}&package_id=${package_id}&package_name=${package_name}&package_price=${package_price}&payment_method=${payment_method}&payment_detail=${payment_detail}&package_credit=${package_credit}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  } catch (error) {
    return console.error("API'den veri çekilirken bir hata oluştu", error);
  } finally {
  }
};

export const addOrdHistoryLog = async (
  accessToken,
  package_id,
  package_name,
  package_price,
  payment_method,
  payment_detail,
  package_credit,
  request,
  responses,
  success
) => {
  try {
    // `https://api.hypergpt.ai/user/add_user_order_history?token=${accessToken}&package_id=${package_id}&package_name=${package_name}&package_price=${package_price}&payment_method=${payment_method}&payment_detail=${payment_detail}&package_credit=${package_credit}`,
    // `https://server.hypergpt.ai/user/add_user_order_history?token=${accessToken}&package_id=${package_id}&package_name=${package_name}&package_price=${package_price}&payment_method=${payment_method}&payment_detail=${payment_detail}&package_credit=${package_credit}`
    if (!accessToken) return;
    const response = await axios.post(
      `https://api.hypergpt.ai/user/add_user_order_history_log?token=${accessToken}&package_id=${package_id}&package_name=${package_name}&package_price=${package_price}&payment_method=${payment_method}&payment_detail=${payment_detail}&package_credit=${package_credit}&request=${request}&response=${responses}&success=${success}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  } catch (error) {
    return console.error("API'den veri çekilirken bir hata oluştu", error);
  } finally {
  }
};

export const hgptjettonTransfer = async (
  id,
  package_name,
  package_buying_price,
  payment_method,
  payment_detail,
  package_credit,
  accessToken,
  getBalance,
  userFriendlyAddress,
  tonConnectUI
) => {
  tonConnectUI.uiOptions = {
    twaReturnUrl: "https://t.me/HyperGPTai_bot",
  };

  let myTransaction;
  const jettonWalletAddress = Address.parse(jettonAddress);

  const jettonMaster = client.open(JettonMaster.create(jettonWalletAddress));
  const jettonContractUserAddress = (
    await jettonMaster.getWalletAddress(Address.parse(userFriendlyAddress))
  ).toString();

  const jettonWallet = new TonWeb.token.jetton.JettonWallet(tonweb.provider, {
    address: jettonContractUserAddress,
  });

  let jettonData = await getJettonData(jettonWallet);

  let rastgeleJettonPrice = await fetchJettonPrice("jWBTC");

  let jettonAmount = await checkBalance(
    jettonData,
    package_buying_price,
    rastgeleJettonPrice
  );

  if (userFriendlyAddress) {
    const body = beginCell()
      .storeUint(0xf8a7ea5, 32)
      .storeUint(0, 64)
      .storeCoins(tonweb.utils.toNano(jettonAmount))
      .storeAddress(Address.parse(receiver_address))
      .storeAddress(Address.parse(receiver_address))
      .storeUint(0, 1)
      .storeCoins(1)
      .storeUint(0, 1)
      .endCell();

    myTransaction = {
      validUntil: Date.now() + 5 * 60 * 1000,
      messages: [
        {
          address: jettonContractUserAddress, // sender jetton wallet
          amount: "40000000", // for commission fees, excess will be returned
          payload: body.toBoc().toString("base64"), // payload with jetton transfer body
        },
      ],
    };
  }

  try {
    const explorerUrl = await sendTx(tonConnectUI, myTransaction);

    await addOrdHistory(
      accessToken,
      id,
      package_name,
      package_buying_price,
      payment_method,
      payment_detail,
      package_credit
    );
    await addOrdHistoryLog(
      accessToken,
      id,
      package_name,
      package_buying_price,
      payment_method,
      payment_detail,
      package_credit,
      "package buying process",
      explorerUrl,
      true
    );
    toast.success(`Transaction success`, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    getBalance();
  } catch (error) {
    if (error.message.includes("Reject request")) {
      await addOrdHistoryLog(
        accessToken,
        id,
        package_name,
        package_buying_price,
        payment_method,
        payment_detail,
        package_credit,
        "package buying process",
        "user denied signature!!",
        false
      );
      return toast.warning("User denied transaction signature!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  }
};

export const tonTransfer = async (
  id,
  package_name,
  package_buying_price,
  payment_method,
  payment_detail,
  package_credit,
  accessToken,
  getBalance,
  userFriendlyAddress,
  tonConnectUI
) => {
  tonConnectUI.uiOptions = {
    twaReturnUrl: "https://t.me/HyperGPTai_bot",
  };

  let rastgeleJettonPrice = await fetchJettonPrice("TON");

  console.log("rastgeleJettonPrice:", rastgeleJettonPrice);

  console.log("ortaya çıkan miktar", package_buying_price / rastgeleJettonPrice);
  const jettonAmount = (package_buying_price / rastgeleJettonPrice)
    .toString()
    .slice(0, 9);

  console.log("jettonAmount:", jettonAmount);
  console.log("toNano jettonAmount:", tonweb.utils.toNano(jettonAmount).toNumber());

  console.log("address parse:", Address.parse(receiver_address).toRawString());

  const userTonBalance = await client.getBalance(userFriendlyAddress);
  console.log("userTonBalance:", userTonBalance);

  console.log("Number(tonweb.utils.fromNano(userTonBalance)):", Number(userTonBalance));

  if (Number(userTonBalance) < tonweb.utils.toNano(jettonAmount).toNumber()) {
    return toast.warning("You don't have enough balance!", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  }

  const transaction = {
    validUntil: Math.round(Date.now() / 1000) + 600,
    messages: [
      {
        // address: Address.parse(receiver_address).toRawString(), // destination address
        // amount: tonweb.utils.toNano(jettonAmount).toNumber(), // amount in nano tokens
        address: Address.parse(
          "UQC0OSmNuvABUjx1Te1K_XrVfcXM6cf0cRmYVugdQv_4Ivu6"
        ).toRawString(), // destination address
        amount: "1000000",
      },
    ],
  };

  try {
    const explorerUrl = await sendTx(tonConnectUI, transaction);

    console.log("explorerUrl:", explorerUrl);
    await addOrdHistory(
      accessToken,
      id,
      package_name,
      package_buying_price,
      payment_method,
      payment_detail,
      package_credit
    );
    await addOrdHistoryLog(
      accessToken,
      id,
      package_name,
      package_buying_price,
      payment_method,
      payment_detail,
      package_credit,
      "package buying process",
      explorerUrl,
      true
    );
    toast.success(`Transaction success`, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    getBalance();
  } catch (error) {
    if (error.message.includes("Reject request")) {
      await addOrdHistoryLog(
        accessToken,
        id,
        package_name,
        package_buying_price,
        payment_method,
        payment_detail,
        package_credit,
        "package buying process",
        "user denied signature!!",
        false
      );
      return toast.warning("User denied transaction signature!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  }
};
