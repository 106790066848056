import React, { useEffect, useState } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import axios from "axios";
import Loading from "../../components/Loading";
import Logo from "../../assets/images/logoOther.svg";
import UserIcon from "../../assets/icon/user.svg";
import RobotIcon from "../../assets/icon/robot.svg";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";

import { ReactMarkdown } from "react-markdown/lib/react-markdown";

const HyperChat = ({ token, generalPath, accessToken }) => {
  const [code, setCode] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ prompt: "" });
  const [balance, setBalance] = useState(0);
  const [activeBalance, setActiveBalance] = useState(false);
  const [isLoadingBotResponse, setIsLoadingBotResponse] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showGlobalAlert, setShowGlobalAlert] = useState(false);
  const [messages, setMessages] = useState([]);
  const [version, setVersion] = useState("v1");
  const messagesEndRef = React.useRef(null);

  const createCodeChat = async (data) => {
    try {
      const response = await axios.post(
        `https://api.hypergpt.ai/ai/gpt3?User=${data.user}&Prompt=${data.Prompt}&name=HyperChat&token=${accessToken}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("createCodeChat error:", error);
      throw error;
    }
  };

  const handleCreateCode = async (data, accessToken) => {
    if (data) {
      try {
        const datas = await createCodeChat(data, accessToken);

        setCode(datas);
        setLoading(false);
        if (datas) {
          setActiveBalance(true);
        }

        return datas;
      } catch (error) {
        console.error("Error creating post:", error);
        return { content: "" };
      }
    } else {
      return { content: "" };
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !isLoadingBotResponse) {
      e.preventDefault(); // Formun varsayılan davranışını engelle
      exampleUsage();
    }
  };

  const exampleUsage = async () => {
    if (!data.prompt.trim()) {
      setShowAlert(true);
      return;
    } else {
      setShowAlert(false);
    }

    if (balance <= 0) {
      setShowGlobalAlert(true);
      setTimeout(() => {
        setShowGlobalAlert(false);
      }, 300000); // 3 saniye sonra hata resmini gizle
    }
    setLoading(true);
    setIsButtonDisabled(true);
    setIsLoadingBotResponse(true);
    setData({ prompt: "" });

    const lastMessage = messages[messages.length - 1];

    const postData =
      version === "v1"
        ? {
            user: "assistant",
            previousMessage: lastMessage,
            Prompt: `${data.prompt}. outputformat:markdown`,
          }
        : {
            previousMessage: lastMessage,
            Prompt: `${data.prompt}. outputformat:markdown`,
          };

    setMessages((prevMessages) => [
      ...prevMessages,
      { isUser: true, text: data.prompt },
    ]);

    try {
      const response = await handleCreateCode(postData);
      setIsLoadingBotResponse(false);

      setMessages((prevMessages) => [
        ...prevMessages,
        { isUser: false, text: response?.content },
      ]);
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setLoading(false);
      setIsButtonDisabled(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleNewChat = () => {
    setMessages([]); // Mesajları temizle
    setData({ prompt: "" }); // Giriş alanını temizle
    setIsLoadingBotResponse(false); // İşlem devam ediyorsa, yükleniyor durumunu temizle
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]); // `messages` arrayinin her değiştiğinde bu effecti çalıştır

  return (
    <div ref={messagesEndRef} className="container">
      <Navbar
        title={"HyperApps / HyperChat"}
        path={`/hyperApps${generalPath}`}
      />

      {showAlert && (
        <div role="alert" style={{ zIndex: 1030, width: "82%" }}>
          Please fill the prompt
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => setShowAlert(false)}
          ></button>
        </div>
      )}

      <div
        style={{
          marginTop: "2rem",
        }}
        className="chatContainer"
      >
        <div className="chat-wrapper">
          {messages.map((message, index) => (
            <p key={index} className="chat-container">
              {message.isUser ? (
                <div className="user-message">
                  <ReactMarkdown>{message.text}</ReactMarkdown>
                </div>
              ) : (
                <div
                  key={index}
                  className="bot-message"
                  // avgTypingDelay={10}
                  // stdTypingDelay={1}
                >
                  {message?.text?.includes("```") ? (
                    message.text.split("```").map((part, index) =>
                      index % 2 === 1 ? (
                        <SyntaxHighlighter
                          key={index}
                          language="javascript"
                          style={materialDark}
                          wrapLines={true}
                          customStyle={{
                            marginTop: "1rem",
                            padding: "1em",
                            paddingTop: "0em",
                            backgroundColor: "#1E1E1E",
                            borderRadius: "0.7rem",
                          }}
                        >
                          {part}
                        </SyntaxHighlighter>
                      ) : (
                        part
                      )
                    )
                  ) : (
                    <ReactMarkdown>{message.text}</ReactMarkdown>
                  )}
                </div>
              )}
            </p>
          ))}
          {isLoadingBotResponse && (
            <p className="bot-message-other">
              <strong>
                <img
                  style={{
                    width: "40px",
                    marginRight: "1rem",
                    float: "left",
                  }}
                  src={RobotIcon}
                  alt=""
                />
              </strong>
              <span className="loading-dots">
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </span>
            </p>
          )}
        </div>
      </div>

      <div
        style={{
          position: "fixed",
          top: "77px",
          width: "100%",
        }}
        className="chat-footer"
      >
        <div className="input-wrapper">
          <div
            style={{
              borderRadius: "1rem",
              padding: "0.5rem",
            }}
            className="input-group relative chat-input-bar m-auto  "
          >
            <input
              onKeyDown={handleKeyDown}
              className="search-input"
              type="text"
              placeholder="Please enter prompt"
              name="prompt"
              onChange={handleChange}
              value={data.prompt}
            />
            <div className="input-group-append absolute display-flex">
              <button
                onClick={exampleUsage}
                className="btn btn-light btn-sm h-42"
                type="button"
                disabled={isButtonDisabled || isLoadingBotResponse}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HyperChat;
