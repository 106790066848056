import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../../components/Navbar";
import Loading from "../../components/Loading";
import hyperLogo from "../../assets/icon/hyper.svg";

const HyperStore = ({ token, profileData, generalPath, accessToken }) => {
  const [apps, setApps] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Yeni state

  useEffect(() => {
    const fetchApps = async () => {
      try {
        setIsLoading(true);
        const url = `https://api.hypergpt.ai/user/allApps?page=1&count=100`;
        const response = await axios.get(url);

        const sortedApps = response.data.result.sort(
          (a, b) => a.order - b.order
        );

        setApps({ ...response.data, result: sortedApps }); // sortedApps ile setApps'u güncelle
      } catch (error) {
        console.error("API'den veri çekilirken bir hata oluştu", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchApps();
  }, [accessToken]);

  const ListCard = (app) => (
    <div className="listCard" key={app.appid}>
      <div className="card-image">
        <img
          className="card-icon"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = hyperLogo;
          }}
          src={app.icon}
          alt={app.name}
        />
        <div className="card-content">
          <h4 className="card-title">{app.name}</h4>
          <span title={app.description} className="card-description">
            {app.description && app.description.length > 50
              ? app.description.substring(0, 70) + "..."
              : app.description}
          </span>
        </div>
      </div>
      <div className="card-button-container">
        <a
          className="card-button"
          href={app.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          Visit
        </a>
      </div>
    </div>
  );

  return (
    <div className="container">
      {!isLoading && <Navbar title={"HyperStore"} path={`/${generalPath}`} />}
      {isLoading ? <Loading /> : apps.result.map((app) => ListCard(app))}
    </div>
  );
};

export default HyperStore;
