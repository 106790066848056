// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HyperImages_containerContent__ye05m {
  height: 100vh;
  display: flex;
  padding-top: 3rem;
  gap: 1rem;
  flex-direction: column;
}
.HyperImages_containerContent__ye05m .HyperImages_generateButton__SFvOU {
  width: 100% !important;
  height: 61px !important;
}
.HyperImages_containerContent__ye05m .HyperImages_uploadImage__Tfkef {
  background-color: rgba(125, 50, 249, 0.1411764706);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px dashed #7d32f9;
  padding: 1rem;
  border-radius: 5px;
  width: 250px;
}
.HyperImages_containerContent__ye05m .HyperImages_uploadImage__Tfkef p {
  color: #fff;
}
.HyperImages_containerContent__ye05m .HyperImages_uploadImage__Tfkef img {
  border-radius: 5px;
}
.HyperImages_containerContent__ye05m button {
  width: 250px;
  height: 61px !important;
  border-radius: 5px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #7d32f9;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.165px;
  color: white;
}

.HyperImages_previewImage__3s3SV {
  background-color: rgba(126, 50, 249, 0.021);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px dashed rgba(126, 50, 249, 0.2470588235);
  border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/hyperImages/HyperImages.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,iBAAA;EACA,SAAA;EACA,sBAAA;AACF;AAAE;EACE,sBAAA;EACA,uBAAA;AAEJ;AAAE;EACE,kDAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,0BAAA;EACA,aAAA;EACA,kBAAA;EACA,YAAA;AAEJ;AADI;EACE,WAAA;AAGN;AADI;EACE,kBAAA;AAGN;AAAE;EACE,YAAA;EACA,uBAAA;EACA,kBAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;EACA,wBAAA;EACA,YAAA;AAEJ;;AACA;EACE,2CAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,mDAAA;EACA,kBAAA;AAEF","sourcesContent":[".containerContent {\n  height: 100vh;\n  display: flex;\n  padding-top: 3rem;\n  gap: 1rem;\n  flex-direction: column;\n  .generateButton {\n    width: 100% !important;\n    height: 61px !important;\n  }\n  .uploadImage {\n    background-color: #7d32f924;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    border: 2px dashed #7d32f9;\n    padding: 1rem;\n    border-radius: 5px;\n    width: 250px;\n    p {\n      color: #fff;\n    }\n    img {\n      border-radius: 5px;\n    }\n  }\n  button {\n    width: 250px;\n    height: 61px !important;\n    border-radius: 5px;\n    color: #fff;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: #7d32f9;\n    border: none;\n    color: #fff;\n    font-size: 16px;\n    font-weight: 700;\n    letter-spacing: -0.165px;\n    color: white;\n  }\n}\n.previewImage {\n  background-color: rgba(126, 50, 249, 0.021);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  border: 2px dashed #7e32f93f;\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerContent": `HyperImages_containerContent__ye05m`,
	"generateButton": `HyperImages_generateButton__SFvOU`,
	"uploadImage": `HyperImages_uploadImage__Tfkef`,
	"previewImage": `HyperImages_previewImage__3s3SV`
};
export default ___CSS_LOADER_EXPORT___;
