import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import axios from "axios";
import styles from "./HyperPortraits.module.scss";

const HyperOcr = ({ token, generalPath, accessToken }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [ocrData, setOcrData] = useState(null);
  const [promptTitle, setPromptTitle] = useState("");
  const [activeBalance, setActiveBalance] = useState(false);
  const [balance, setBalance] = useState(0);

  const [showAlert, setShowAlert] = useState(false);
  const [showGlobalAlert, setShowGlobalAlert] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);

  const [data, setData] = useState({
    prompt: "",
    negativePrompt: "",
    number: 1,
  });
  const [selectedStyles, setSelectedStyles] = useState();

  const createTextToDavinci = async (postData) => {
    try {
      const response = await axios.post(
        `https://api.hypergpt.ai/ai/davincitext2img?prompt=${postData.Prompt}&token=${accessToken}&number=${postData.number}&w=512&h=512`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      navigate("/result", {
        state: { data: response.data, from: location.pathname },
      });
      console.log("response.data", response.data);
      return response.data;
    } catch (error) {}
  };

  const handleCreatePost = async (postData) => {
    if (postData) {
      try {
        const data = await createTextToDavinci(postData); // await keyword added here
        setImageData(data);
        setLoading(false);
        setActiveBalance(!activeBalance);
      } catch (error) {
        console.error("Error creating post:", error);
      }
    }
  };

  const exampleUsage = async () => {
    await setOpenHistory(false);

    if (balance <= 0) {
      setShowGlobalAlert(true);
      setTimeout(() => {
        setShowGlobalAlert(false);
      }, 3000); // 3 saniye sonra hata resmini gizle
    }
    if (!data.prompt) {
      setShowAlert(`Please fill the prompt`);
      return;
    } else if (!data.number || data.number <= 0) {
      setShowAlert(`Please set a valid image count`);
      return;
    }

    setLoading(true);

    const postData = {
      Prompt:
        `${data.prompt};` +
        " " +
        selectedStyles +
        " " +
        `${Object.keys(selectedStyles).length === 0 ? "" : "style;"}`,
      number: data.number,
    };

    setPromptTitle(postData.Prompt);
    await handleCreatePost(postData);
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    setData({ ...data, [name]: value });
  };

  if (showAlert) {
    setTimeout(() => {
      setShowAlert("");
    }, 3000);
  }

  return (
    <div className="container">
      <Navbar
        title={"HyperApps / HyperOcr"}
        path={`/hyperApps${generalPath}`}
      />

      <div className={styles.containerContent}>
        <div className="row mb-8 flex-column input-wrappers">
          <label className="col-lg-3 col-form-label text-nowrap light-span-text	">
            Prompt
          </label>
          <div className="col-lg-12 input-wrappers">
            <div
              className="spinner spinner-sm spinner-primary spinner-right
            "
            >
              <input
                className="form-control input-change-border
                chat-input-bar
                "
                type="text"
                placeholder="Please enter prompt"
                name="prompt"
                value={data.prompt}
                style={{ fontSize: "12px" }}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>

        <div className="row mb-4 flex-column input-wrappers">
          <label className="col-lg-3 col-form-label text-nowrap light-span-text">
            Styles
          </label>
          <div className="col">
            <select
              className="form-select"
              value={selectedStyles}
              onChange={(e) => setSelectedStyles(e.target.value)}
            >
              <option value="">Choose a style</option>
              <option value="Enhance">Enhance</option>
              <option value="Anime">Anime</option>
              <option value="Photographic">Photographic</option>
              <option value="Digital Art">Digital Art</option>
              <option value="Comic Book">Comic Book</option>
              <option value="Fantasy Art">Fantasy Art</option>
              <option value="Analog Film">Analog Film</option>
              <option value="Neon Punk">Neon Punk</option>
              <option value="Isometric">Isometric</option>
              <option value="Lowpoly">Lowpoly</option>
              <option value="Origami">Origami</option>
              <option value="Line Art">Line Art</option>
              <option value="Craft Clay">Craft Clay</option>
              <option value="Cinematik">Cinematik</option>
              <option value="3D Model">3D Model</option>
              <option value="Pixel Art">Pixel Art</option>
            </select>
          </div>
        </div>

        <button
          type="button"
          className={styles.generateButton}
          onClick={exampleUsage}
        >
          {loading ? "Processing..." : "Generate"}
        </button>
      </div>
    </div>
  );
};

export default HyperOcr;
