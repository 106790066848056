import React, { useEffect, useState } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import axios from "axios";
import Loading from "../../components/Loading";

const MySpace = ({ token, generalPath }) => {
  const [apps, setApps] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Yeni state

  useEffect(() => {
    const fetchApps = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `https://api.hypergpt.ai/app/getIntegratedApps?token=${token}`
        );
        const sortedApps = response.data.sort((a, b) => a.order - b.order);
        setApps(sortedApps);
      } catch (error) {
        console.error("API'den veri çekilirken bir hata oluştu", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchApps();
  }, [token]);

  const ListCard = (app) => (
    <div className="listCard" key={app.appid}>
      <div className="card-image">
        <img src={app.icon} alt={app.name} />
        <div className="card-content">
          <h4 className="card-title">{app.name}</h4>
          <span title={app.description} className="card-description">
            {app.description.length > 50
              ? app.description.substring(0, 50) + "..."
              : app.description}
          </span>
        </div>
      </div>

      <div className="card-button-container">
        <Link to={app.name.replace(/\s+/g, "")} className="card-button">
          Open
        </Link>
      </div>
    </div>
  );

  return (
    <div className="container">
      {!isLoading && <Navbar title={"HyperApps"} path={`/${generalPath}`} />}
      {isLoading ? <Loading /> : apps.map((app) => ListCard(app))}
    </div>
  );
};

export default MySpace;
